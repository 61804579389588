import type { ReferralInfo } from '@getstep/sdk/dist/http/models/Referrals';
import { when } from 'mobx';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { AppsFlyerLinker } from '../linking/AppsFlyerLinker';
import { useStores } from '../useStores';
export const useClickTracker = (props?: ClickTrackerProps) => {
  const {
    query,
    isReady
  } = useRouter();
  const {
    linkingStore
  } = useStores();
  const {
    feature
  } = props || {};
  useEffect(() => {
    if (!isReady) {
      return;
    }
    when(() => linkingStore.loaded, () => {
      const params = AppsFlyerLinker.appsFlyerParams(query);
      linkingStore.update({
        ...params,
        feature: feature ?? params.feature
      }, 'feature');
    });
  }, [feature, query, isReady]);
  return null;
};
interface ClickTrackerProps {
  feature?: string;
  referralInfo?: ReferralInfo;
  params?: Record<string, unknown>;
}