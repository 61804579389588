import classNames from 'classnames';
import type { FunctionComponent, ReactNode } from 'react';
import { useEffect, useState } from 'react';
import type { BannerFragment } from '../../graphql/fragments/BannerFragment.graphql';
import type { ThemeColorFragment } from '../../graphql/fragments/ThemeColorFragment.graphql';
import { useDidMount } from '../../lib/hooks/useDidMount';
import { useUIStore } from '../../lib/store/useUIStore';
import { withTheme } from '../../lib/utils/withTheme';
import { isPrd } from '../../shared/util/env';
import { useIsAppPage } from '../../shared/util/link';
import { DevMenu } from '../dev-menu';
import type { DisclaimersProps } from '../disclaimers/disclaimers';
import { ObservedModal } from '../modal';
import { ObservedSnackbar } from '../snackbar';
import { Footer } from './footer';
import { Header } from './header';
import { HeaderBanner } from './header-banner';
import styles from './layout.module.scss';

/** Main website content container. Includes header and footer. */
export const Layout: FunctionComponent<Props> = ({
  children,
  disclaimers,
  theme,
  asPath,
  announcement
}) => {
  const mounted = useDidMount();
  const {
    webview
  } = useUIStore();
  const [showBanner, setShowBanner] = useState(!!announcement);
  const hasBanner = !!announcement && showBanner;

  // once router is ready, it will include the path that accounts for rewrites.
  // this is useful because during prerendering "asPath" is not the rewritten path.
  const renderedInsideApp = useIsAppPage(asPath);
  useEffect(() => {
    webview.setIsWebview(renderedInsideApp);
    if (renderedInsideApp && typeof window.Cookiebot.submitCustomConsent !== 'undefined' && typeof window.Cookiebot.hide !== 'undefined') {
      window.Cookiebot.submitCustomConsent(true, true, true);
      window.Cookiebot.hide();
    }
  }, [renderedInsideApp, webview]);
  return withTheme(theme?.key, <div className={styles.wrapper}>
            {hasBanner && <HeaderBanner {...announcement} onClose={() => setShowBanner(false)} />}
            {!renderedInsideApp && <Header hasBanner={hasBanner} />}

            <main className={classNames(styles.main, !renderedInsideApp && styles['with-header'])} data-loaded={mounted} data-theme={typeof theme === 'string' ? theme : theme?.key}>
                {children}
            </main>

            {!renderedInsideApp && <Footer theme={theme?.key as string} disclaimers={disclaimers} />}

            {!isPrd() && mounted && <DevMenu />}

            <>
                <ObservedModal />
                <ObservedSnackbar />
            </>
        </div>);
};
type Props = {
  children: ReactNode;
  className?: string;
  asPath: string;
  disclaimers?: DisclaimersProps;
  theme: ThemeColorFragment;
  announcement?: BannerFragment;
};