import classNames from 'classnames';
import type { FunctionComponent } from 'react';
import { tracker } from '../../lib/store/tracker/useTracker';
import { useStores } from '../../lib/store/useStores';
import { LINK } from '../../shared/util/link';
import type { ButtonTrackingProps } from '../button/props';
import * as ICONS from '../icons/mobile-store';
import styles from './app-button.module.scss';

/** Android/iOS app store button */
export const AppButton: FunctionComponent<React.PropsWithChildren<Props>> = ({
  type,
  color,
  placement = 'section',
  containerElementId,
  ...restOfProps
}) => {
  const Icon = ICONS[type];
  const href = LINK.app[type]();
  const {
    deviceStore: {
      os
    }
  } = useStores();
  if (!href) return null;
  const name = type.toLowerCase();
  return <a href={href} target='_blank' rel='noopener noreferrer' onClick={() => tracker.clickButton(type, {
    os,
    placement,
    containerElementId
  })} className={classNames(styles.button, styles[name], os && styles[`${name}-on-${os.toLowerCase()}`])} {...restOfProps} data-sentry-component="AppButton" data-sentry-source-file="index.tsx">
            <Icon height='100%' color={color} data-sentry-element="Icon" data-sentry-source-file="index.tsx" />
        </a>;
};
interface Props extends Partial<ButtonTrackingProps> {
  type: 'AppStore' | 'GooglePlay';
  color?: 'step-white' | 'step-midnight';
}