import classNames from 'classnames';
import type { FunctionComponent } from 'react';
import { AppButton } from '../../components/app-button';
import styles from './article-apps.module.scss';

/**
 * Displays AppStore and GooglePlay buttons.
 */
export const ArticleApps: FunctionComponent<Props> = ({
  background = 'light'
}) => <div className={classNames(styles.section, styles.apps, background == 'light' ? styles.light : styles.dark)} data-sentry-component="ArticleApps" data-sentry-source-file="index.tsx">
        {(['GooglePlay', 'AppStore'] as const).map(type => <div key={type} className={styles.app}>
                <AppButton type={type} />
            </div>)}
    </div>;
interface Props {
  background?: 'light' | 'dark';
}