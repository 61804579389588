'use client';

import type { FunctionComponent } from 'react';
import { useEffectAsync } from '../../lib/hooks/useEffectAsync';
import { isPrd } from '../../shared/util/env';
export const ShakeProvider: FunctionComponent = () => {
  useEffectAsync(async () => {
    // only use on dev/stg
    if (isPrd()) return;
    const scriptId = 'shake-script';

    // Prevent adding multiple scripts
    if (document.getElementById(scriptId)) return;
    const script = document.createElement('script');
    script.id = scriptId;
    script.type = 'module';
    script.textContent = `
          import Shake from 'https://cdn.jsdelivr.net/npm/@shakebugs/browser@latest/+esm';
          window.Shake = Shake;
          Shake.start('BYvdElepMOFOjVuJVeHGzSJkc93p5hhsp7KVdKpIuM92jjfsR4n99ju');
        `;
    document.body.appendChild(script);
  }, []);
  return null;
};