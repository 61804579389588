import { mergeWith } from 'lodash'
import type { DeepPartial } from 'react-hook-form'

/**
 * Parameters for the app link. These will be sent to the linking provider to generate a link.
 */
export type AppLinkParameters = {
    campaign: string
    channel: string
    deepLinkValue?: string
    feature?: string
    mediaSource: string
    templateId?: SMSTemplate
    data: AppLinkData
}

export enum SMSTemplate {
    INVEST_WAITLIST = 'invest-waitlist',
    SCHOOL_REQUEST_WAITLIST = 'school-request-waitlist',
}

/**
 * Data that gets packaged into the link.
 * You need these to be able to pluck data for display.
 */
export type AppLinkData = {
    admin_full_name?: string
    amount_currency?: string
    amount_value?: string
    code?: string
    comment?: string
    expires_at_ms?: string
    phone_suffix?: string
    sender_first_name?: string
    sender_full_name?: string
    sender_last_name?: string
    sender_phone_suffix?: string
    referred_award_formatted?: string
    sponsor_full_name?: string
    sponsee_full_name?: string
    segment_user_id?: string
    inviter_first_name?: string
    inviter_last_name?: string
    inviter_full_name?: string
    $custom_sms_text?: string
    $desktop_url?: string
    $canonical_url?: string

    /**
     * has to be undefined on mobile for deeplinking to work
     */
    $fallback_url?: string

    /**
     * Flag used to determine whether mobile should use the expedited onboarding flow
     */
    expedited_onboarding?: boolean

    pid?: string
    c?: string
    af_c_id?: string
    af_siteid?: string
    af_sub_siteid?: string
    clickid?: string
    af_click_lookback?: string
}

export const DEFAULT_PARAMETERS: AppLinkParameters = {
    campaign: 'Website',
    channel: 'Website',
    mediaSource: 'web_organic',
    data: {},
}

/**
 * Merges the sources with priority to the left (the first argument is the highest priority).
 * Does not modify the given parameters. Applies defaults.
 */
export function mergeParameters(
    ...sources: DeepPartial<AppLinkParameters>[]
): AppLinkParameters {
    return mergeWith({}, DEFAULT_PARAMETERS, ...sources)
}
